@import '../../../styles/abstracts/variables';

@media only screen and (max-width: 1199px) {
  .subheader-confirm-inspection-desktop {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .container-schedule-insp-home-desktop {
    .wrapper {
      .image-background-desktop {
        z-index: 1;
        background-image: url('../../../images//inspection-desktop/inspection-home-desktop/inspection-home-background-desktop.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 100%;
        width: 100%;
        max-height: 242px;
        height: 242px;
      }

      .card-inspection-home {
        z-index: 100;
        background-color: $white;
        margin-top: -9rem;
        max-width: 80%;
        width: 80%;
        height: 70%;
        max-height: 70%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #e9e9e9;
        margin-bottom: 5rem;
      }

      .title-inspection-confirm {
        max-width: 70%;
        width: 70%;
        margin-left: auto;
        margin-right: 0;
      }

      .title-inspection-confirm > h1 {
        max-width: 60%;
        padding-left: 2rem;
        font-size: 33px;
        color: $purple;
        padding-top: 0.5rem;
      }

      .advisor-continer {
        float: right;
        padding-right: 20rem;
        margin-top: -6rem;
      }

      .image-confirm-inspection {
        margin-right: -3rem;
      }

      .info-visit-agend-p {
        margin-top: 7rem;
        text-align: right;
        margin-left: 18rem;
        max-width: 20%;
        font-size: 30px;
        color: #1a1446;
        direction: rtl;
      }

      .info-confirm {
        max-width: 27%;
        text-align: justify;
        margin-left: 14rem;
        direction: rtl;
        font-size: 20px;
        color: #323744;
      }

      .horizontal-line {
        margin-top: 7rem !important;
      }

      .button-confirm-agend {
        max-width: 30%;
        width: 30%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
