@import '../../../../styles/abstracts/variables';

@media only screen and (max-width: 1199px) {
  .subheader-confirm-inspection-desktop {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .container-inspection-scheduled-confirm-desktop {
    .wrapper {
      .image-background-desktop {
        z-index: 1;
        background-image: url('../../../../images//inspection-desktop/inspection-home-desktop/inspection-home-background-desktop.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 100%;
        width: 100%;
        max-height: 242px;
        height: 242px;
      }

      .card-inspection-home {
        z-index: 100;
        background-color: $white;
        margin-top: -9rem;
        max-width: 80%;
        width: 80%;
        height: 70%;
        max-height: 70%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #e9e9e9;
        margin-bottom: 5rem;
      }

      .title-inspection-scheduled-confirm {
        max-width: 22% !important;
      }

      .title-inspection-scheduled-confirm > h1 {
        font-size: 27px !important;
      }

      .title-inspection-scheduled-confirm > h1 > strong {
        font-size: 41px !important;
      }

      .thanks-inspection-shceduled-confirm {
        font-size: 30px !important;
        max-width: 25%;
        margin-left: auto;
        margin-right: auto;
        padding-right: 15rem;
        margin-top: 5rem;
        margin-bottom: -5rem;
      }

      .image-confirm-inspection-scheduled {
        margin-right: 17rem;
      }

      .send-info-inspection-scheduled-confirm {
        max-width: 30%;
        text-align: justify;
        direction: rtl;
        padding-right: 25rem;
        margin-top: -4rem;
        margin-bottom: 8rem;
      }

      .horizontal-line-inspection-scheduled-confirm {
        width: 70%;
      }

      .button-confirm-agend-inspection-scheduled-confirm {
        max-width: 30%;
      }
    }
  }
}
