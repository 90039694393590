@import '../../../styles/abstracts/variables';

.contact-us-component {
  text-align: center;
  background-color: $purple-dark;
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: $white;
}

.contact-us-h3 {
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 1em;
}

.contact-us-p {
  margin-left: 0;
  margin-bottom: 0.5em;
}

.contact-us-div {
  margin-bottom: 2rem;
  margin-left: 0;
}

.contact-us-img {
  float: left;
  margin-left: 1rem;
}
