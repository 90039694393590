@import '../../styles/abstracts/variables';

.title-inspection {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: 100 !important;
  font-size: 2rem;
  color: $purple;
}

.margin-container {
  padding-bottom: 5rem;
}

.interactive-row {
  margin-top: 87rem;
}

.text-info-p {
  text-align: center;
  font-size: 17px;
  margin-bottom: 4rem;
}
.box-date-info {
  margin-top: 3rem;
}

.line-divided-inspection-home {
  max-width: 100%;
  width: 100%;
}

.button-date-info {
  margin-top: 3rem;
}

.choose-hour-text {
  margin-top: 17rem;
}
.choose-hour-p {
  text-align: center;
  font-size: 17px;
}

.personal-info-title {
  margin-bottom: 4rem;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25%;
}

.personal-info-title > img {
  float: left;
}

.personal-info-title > h1 {
  font-size: 1.2em;
  padding-top: 1rem;
  padding-left: 4rem;
  letter-spacing: 0cm;
}

@media only screen and (max-width: 1199px) {
  .title-card-inspection-home {
    display: none;
  }

  .subtitle-card-inspection-home {
    display: none;
  }

  .table-description-vehicle {
    display: none;
  }

  .note-info-container {
    display: none;
  }

  .line-divided-inspection-home {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .title-inspection {
    display: none;
  }
}
