@import '../../../styles/abstracts/variables';

.container-inspection-scheduled-confirm {
  .row {
    .title-inspection-scheduled-confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 96%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    .title-inspection-scheduled-confirm > img {
      float: left;
    }

    .title-inspection-scheduled-confirm > h1 {
      font-size: 22px;
      padding-left: 0.5rem;
      color: $purple;
    }

    .title-inspection-scheduled-confirm > h1 > strong {
      font-size: 24px;
    }

    .thanks-inspection-shceduled-confirm {
      text-align: center;
      font-size: 17px;
      margin: 2rem 1.3rem;
      color: $purple;
    }

    .image-confirm-inspection-scheduled {
      display: block;
      margin: auto;
    }

    .send-info-inspection-scheduled-confirm {
      max-width: 74%;
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
      padding: 0.4rem;
      border-radius: 2px;
      background-color: $gray-button-background;
    }

    .info-confirm-inspection-scheduled-confirm {
      font-size: 14px;
      text-align: center;
      color: #323744;
      font-weight: bold;
      padding: 0.2rem;
    }

    .horizontal-line-inspection-scheduled-confirm {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      background-color: #dadada;
      margin-top: 2rem;
    }

    .button-confirm-agend-inspection-scheduled-confirm {
      margin-top: 2.5rem;
      margin-bottom: 10rem;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .confirm-ins-sp {
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .container-inspection-scheduled-confirm {
    .row {
      .info-confirm-inspection-scheduled-confirm {
        text-align: right;
      }
    }
  }
}
