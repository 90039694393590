@import '../../../styles/abstracts/variables';

@media only screen and (min-width: 1200px) {
  .drop-arrow-scheduled-home-inspection {
    display: none;
  }

  .container-schedule-insp-home-desktop {
    .wrapper {
      .image-background-desktop {
        z-index: 1;
        background-image: url('../../../images//inspection-desktop/inspection-home-desktop/inspection-home-background-desktop.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 100%;
        width: 100%;
        max-height: 242px;
        height: 242px;
      }

      .card-inspection-home {
        z-index: 100;
        background-color: $white;
        margin-top: -9rem;
        max-width: 80%;
        width: 80%;
        height: 70%;
        max-height: 70%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #e9e9e9;
        margin-bottom: 5rem;
      }

      .title-card-inspection-home {
        font-size: 40px;
        text-align: center;
        color: $purple;
        font-weight: bold;
        padding-top: 4rem;
      }

      .subtitle-card-inspection-home {
        font-size: 20px;
        text-align: center;
        color: #323744;
        margin-top: 0.5rem;
        letter-spacing: 0cm;
      }

      .table-description-vehicle {
        display: flex;
        background-color: #f5f5f5;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
        height: 4rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 8rem;
        margin-bottom: 3rem;
        max-width: 80%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4rem;
      }

      .table-info-vehicle {
        margin-left: auto;
        margin-right: auto;
      }

      .info-plate {
        margin-top: 1rem;
        margin-left: -0.5rem;
        font-weight: bold;
        margin-right: 4rem;
      }

      .technical-data-sheet {
        font-size: 12px;
        color: #565656;
        letter-spacing: 0cm;
      }

      .technical-vehicle-data {
        font-size: 18px;
        color: #808080;
        margin-right: 6rem;
      }

      .note-info-container {
        display: flex;
        background-color: #99e5ea;
        border: 1px solid #28a3af;
        border-radius: 3px;
        max-width: 55%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6rem;
        padding: 1rem;
      }

      .exclamation-image-note {
        margin-left: 1rem;
      }

      .note-info-container p {
        font-size: 14px;
        color: #1a1446;
        margin-left: 0.8rem;
      }

      .personal-info-title {
        margin-bottom: 3rem;
        max-width: 30%;
        width: 30%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
      }

      .button-date-info {
        max-width: 30%;
        width: 30%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
