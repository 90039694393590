@import '../../../styles/abstracts/variables';

.container-schedule-inspection-selected {
  .row-margin-container {
    .subtitle-search-inspection {
      font-size: 20px;
      text-align: center;
      color: $purple;
      letter-spacing: 0px;
    }

    .title-search-inspection {
      font-size: 29px;
      text-align: center;
      margin-top: 2rem;
      font-weight: bold;
      color: $purple;
    }

    .text-info-search-inspection {
      text-align: center;
      color: $black-text;
      margin-top: 2rem;
      font-size: 17px;
      max-width: 80%;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    .base-selected-schedule {
      margin: 3rem auto 0 auto;
      max-width: 90%;
    }

    .result-search-cda {
      text-align: center;
      color: $blue-text-cda;
      font-size: 15px;
      max-width: 50%;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      span {
        font-weight: bold;
      }
    }

    .button-map-list {
      margin-top: 3rem;
      margin-bottom: 1rem;
      max-width: 70%;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      letter-spacing: 0px;
    }

    .button-list {
      float: left;
      max-width: 50%;
      width: 50%;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0px;
    }

    .button-map-show {
      max-width: 50%;
      width: 50%;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0px;
    }

    .imgage-map-list {
      max-width: 50%;
      margin-left: -1rem;
      margin-right: auto;
      padding-right: 0.5rem;
    }

    .active {
      background-color: $blue-dark;
      color: $white;
      font-weight: normal;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .subtitle-search-inspection-desktop {
    display: none;
  }

  .title-search-inspection-desktop {
    display: none;
  }

  .text-info-search-inspection-desktop {
    display: none;
  }
}
