@import '../../../../styles/abstracts/variables';

@media only screen and (max-width: 1199px) {
  .container-call-image-desktop {
    display: none;
  }

  .vertical-line {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .header-desktop-container {
    a {
      text-decoration: none;
    }
    .header-icon {
      margin-left: 4rem;
    }

    .vertical-line {
      margin-left: 4rem;
    }

    .text-title {
      margin-left: 15rem;
    }

    .container-call-image-desktop {
      float: right;
      margin-top: -3.2rem;
      width: 18%;

      img {
        float: left;
      }

      p {
        font-size: 15px;
        color: $purple;
        margin-left: 3rem;
        margin-top: 0.6rem;
      }
    }
  }

  .container-call-image {
    display: none;
  }
}
