@import '../../../../styles/abstracts/variables';

@media only screen and (min-width: 1200px) {
  .drop-arrow-scheduled-home-inspection-selected {
    display: none;
  }
  .subtitle-search-inspection {
    display: none;
  }

  .title-search-inspection {
    display: none;
  }

  .text-info-search-inspection {
    display: none;
  }

  .container-schedule-insp-selected-desktop {
    .wrapper {
      .image-background-desktop {
        z-index: 1;
        background-image: url('../../../../images/inspection-desktop/inspection-home-desktop/inspection-home-background-desktop.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 100%;
        width: 100%;
        max-height: 242px;
        height: 242px;
      }

      .card-inspection-home {
        z-index: 100;
        background-color: $white;
        margin-top: -9rem;
        max-width: 80%;
        width: 80%;
        height: 70%;
        max-height: 70%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #e9e9e9;
        margin-bottom: 5rem;
      }

      .subtitle-search-inspection-desktop {
        font-size: 20px;
        text-align: center;
        color: #323744;
        margin-bottom: 2rem;
        letter-spacing: 0px;
      }

      .title-search-inspection-desktop {
        font-size: 40px;
        text-align: center;
        margin-top: 3rem;
        color: #1a1446;
        font-weight: bold;
      }

      .text-info-search-inspection-desktop {
        font-size: 17px;
        text-align: center;
        color: #323744;
        margin-top: 0.5rem;
      }

      .table-description-vehicle {
        display: flex;
        background-color: #f5f5f5;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
        height: 4rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 8rem;
        margin-bottom: 3rem;
        max-width: 80%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
      }

      .table-info-vehicle {
        margin-left: auto;
        margin-right: auto;
      }

      .info-plate {
        margin-top: 1rem;
        margin-left: -0.5rem;
        font-weight: bold;
        margin-right: 4rem;
      }

      .technical-data-sheet {
        font-size: 12px;
        color: #565656;
        letter-spacing: 0px;
      }

      .technical-vehicle-data {
        font-size: 18px;
        color: #808080;
        margin-right: 6rem;
      }

      .base-selected-schedule {
        max-width: 40%;
      }

      .button-map-list {
        max-width: 30%;
        width: 30%;
        margin-left: 2rem;
      }
    }
  }
}
