@import '../../../styles/abstracts/variables';

.plate-restriccion {
  font-size: 1rem;
  min-height: 40rem;

  .line-black {
    padding-bottom: 2em;
    margin: auto;
    width: 80%;
    height: 1px;
    box-shadow: 0 -1px 0px 0 rgba(0, 0, 0, 0.12);
  }

  .title {
    display: flex;
    justify-content: center;
    padding: 1.5em;
    &__img {
      display: block;
      flex-basis: 3.5em;
      background-color: #f4f5f5;
      border-radius: 50%;
      padding-top: 10px;
      img {
        width: 2em;
        height: 2em;
      }
    }
    &__text {
      font-weight: bold;
      font-size: 1.4em;
      padding: 0.5em 1rem;
    }
  }

  .section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    text-align: center;

    &__text {
      display: flex;
      flex-direction: column;
      padding-top: 2em;
      padding-bottom: 2em;

      p {
        width: 17.5em;
        font-size: 1em;
        color: #1a1446;
        font-weight: lighter;
        margin: auto;
        text-align: center;
      }
    }

    &__img {
      display: block;
      padding-bottom: 2em;
    }

    .section__btn {
      width: 20em;
      margin: auto;

      &-finish {
        padding-top: 1em;
        padding-bottom: 3em;

        p {
          font-size: 1.4em;
          letter-spacing: 0.01em;
        }
      }

      &-expert {
        padding-top: 1em;
        padding-bottom: 0.5em;

        p {
          font-size: 1.5em;
          letter-spacing: 0.01em;
        }
      }
    }
  }
}
