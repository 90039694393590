$yellow: #ffd000;
$yellow-transparent: #ffe571;

$white: #ffffff;
$white-light: #dadada;

$purple: #1a1446;
$purple-dark: #29254f;
$purple-light: #910074;

$gray: #8d8d8d;
$gray-dark: #46454c;
$gray-light: #d8d8d8;
$gray-borders: #e9e9e9;
$gray-button-background: #e6e6e6;
$gray-button-shadow: #e9e9e96e;

$green-light: #00d563;

$blue: #28a3af;
$blue-shadow: #73c3ca;
$blue-text-cda: #037b86;
$blue-dark: #00a6b1;

$black-letter: #46454c;
$black-text: #323744;
