@import '../../../../styles/abstracts/variables';

input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea,
select {
  height: 50px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  margin-bottom: 2rem;
}

.autocomplete-input-cities {
  height: 50px;
  padding: 6px 10px;
  background-color: $white;
  border: 1px solid $gray-light;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  margin-bottom: 2rem;
}

.list-result-autocomplete {
  cursor: pointer;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: $gray;

  &:hover {
    background-color: $gray-light;
    color: $gray-dark;
  }
}

.citySelectedCustom {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
}

.cities-autocomplete-result {
  background-color: $white;
  border: 1px solid $gray-light;
  margin-top: -2em;
  z-index: 4;
  position: absolute;
  width: 90%;
}
