@import '../../../styles/abstracts/variables';

.inspection-schedule-list-container {
  margin-top: 4rem;
  max-width: 90%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  .pin-image-workshop {
    float: left;
  }

  .title-list-cda {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 4rem;
  }

  .wrapper-info-inspection-show-list {
    max-width: 80%;
    margin-left: auto;

    .image-list-icon {
      float: left;
      margin-right: 1rem;
    }

    .text-list-info {
      font-size: 16px;
      color: #404040;
    }

    .atention-time-list-title {
      font-size: 16px;
      color: #404040;
      font-weight: bold;
    }

    .atention-days-hour-info-list {
      font-size: 16px;
      color: #7e7e7e;
    }

    .container-list-result {
      margin-top: 2rem;
    }
    .container-subresult {
      margin-top: 1rem;
      color: #28a3af;
    }

    .link-info {
      float: left;
      color: $blue-text-cda;
      margin-left: -2.5rem;
      margin-bottom: 2rem;
    }

    .image-list-icon-color {
      float: left;
      margin-right: 0.2rem;
    }

    .text-info-list-view-more {
      font-size: 14px;
      color: #28a3af;
    }

    .redirection-map-open {
      text-decoration: none;
    }

    .calendar {
      padding-bottom: 2rem;
    }
  }

  .confirm-cda-workshop {
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 100%;
  }
}
