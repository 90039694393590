@import '../../../styles/abstracts/variables';

.footer-component {
  background-color: $purple;
  text-align: center;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $white;
  position: relative;
}

.icons-social-red {
  float: left;
  margin-left: 1rem;
}

.footer-center {
  margin-top: 0.5rem;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.footer-div {
  padding-top: 6rem;
}

.footer-p {
  margin-bottom: 1rem;
}

.footer-a {
  text-decoration: none;
}

.desktop-footer {
  display: flex;
  position: relative;

  &--primary {
    justify-content: center;
    width: 100%;
    background-color: $purple;
    flex-direction: column;
    align-items: center;

    &-container {
      display: flex;
      justify-content: center;
      width: 80%;
      padding-top: 30px;
      flex-direction: column;
      align-items: center;
    }

    .first-line {
      display: flex;

      .contact {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          font-size: 14px;
          line-height: 16px;
          color: $white;
          font-weight: bold;
          margin: 0px 15px 0px 15px;
          span {
            font-weight: normal;
          }
        }
      }

      .social {
        display: flex;
        margin-left: 20px;
        justify-content: center;
        align-items: center;

        p {
          font-size: 14px;
          line-height: 16px;
          color: $white;
          font-weight: bold;
        }

        img {
          margin: 0px 5px 0px 10px;
          width: 33px;
          height: 33px;
        }
      }
    }

    .second-line {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 0px 29px 0px;

      .copyright {
        p {
          font-size: 14px;
          line-height: 16px;
          color: $white;
          font-weight: bold;
        }
      }
      .terms {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;

        &::before {
          content: "";
          border-left: 1px solid $blue;
          height: 25px;
        }

        p {
          font-size: 14px;
          line-height: 16px;
          color: $blue;
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 920px) {
  .footer-component {
    display: none;
  }
}

@media only screen and (max-width: 920px) {
  .desktop-footer {
    display: none;
  }
}