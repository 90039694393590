@import '../../../../styles/abstracts/variables';

.button,
button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
  display: inline-block;
  height: 50px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $gray-light;
  cursor: pointer;
  box-sizing: border-box;
}
