@import '../../../styles/abstracts/variables';

.inspection-schedule-map {
  max-width: 90%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  .close-inspection-view-map-info {
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: right;
  }

  .pin-image-workshop {
    float: left;
  }

  .title-list-cda {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 4rem;
  }

  .wrapper-info-inspection-show-list {
    max-width: 80%;
    margin-left: auto;

    .image-list-icon {
      float: left;
      margin-right: 1rem;
    }

    .text-list-info {
      font-size: 16px;
      color: #404040;
    }

    .atention-time-list-title {
      font-size: 16px;
      color: #404040;
      font-weight: bold;
    }

    .atention-days-hour-info-list {
      font-size: 16px;
      color: #7e7e7e;
    }

    .container-list-result {
      margin-top: 2rem;
    }
    .container-subresult {
      margin-top: 1rem;
      color: #28a3af;
    }

    .calendar {
      padding-bottom: 2rem;
    }

    .confirm-cda-workshop {
      padding-top: 1rem;
      padding-bottom: 1rem;
      max-width: 100%;

      .button-fixed-clear {
        color: #ffffff;
        background-color: #28a3af;
        font-size: 16px;
        font-weight: bold;
        width: 90%;
      }

      .button-fixed-clear-outliner {
        color: #28a3af;
        background-color: #ffffff;
        border: 2px solid;
        font-size: 16px;
        font-weight: bold;
        width: 90%;
      }
    }
  }
}
