@import '../../../styles/abstracts/variables';

.header-component {
  background-color: $yellow;
  padding: 1.5rem 2rem;
}

.header-icon {
  float: left;
  margin-left: -1.5rem;
  margin-top: -6px;
}

.vertical-line {
  width: 1px;
  background-color: $purple-dark;
  height: 31px;
  margin-left: 1rem;
  float: left;
}

.text-title {
  margin-left: 4rem;
  margin-top: 4px;
  color: $purple-dark;
  font-size: 14px;
}

.container-call-image {
  float: right;
  margin-right: -1rem;
  margin-top: -1.8rem;
}
