@import '../../../styles/abstracts/variables';

.drop-arrow-component {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  background: $yellow-transparent;
  border: solid 1px $yellow-transparent;
}

.drop-arrow-component:before,
.drop-arrow-component:after {
  content: ' ';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

.arrow-bottom {
  position: relative;
}
.arrow-bottom:before {
  width: 0;
  height: 0;
  border-right: 60px solid transparent;
  border-top: 30px solid $yellow-transparent;
  border-left: 60px solid transparent;
  border-bottom: 40px solid transparent;
  left: 50%;
  transform: translate(-50%, 15%);
  z-index: -100;
}

.vechicle-data {
  text-align: center;
  font-size: 14px;
  padding-top: 0.5rem;
}

.title-fixed {
  font-size: 24px;
  text-align: center;
  padding-top: 5rem;
  margin-left: 9rem;
  margin-right: 9rem;
}
