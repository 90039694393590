@import '../../../../styles/abstracts/variables';

.document-type-select {
  position: relative;

  select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 7px 10px;
    height: 50px;
    outline: 0;
    font-size: 1em;
    color: #999;
    font-family: 'Quicksand', sans-serif;
    border: 1px solid $gray-light;
    border-radius: 3px;
    position: relative;
    transition: all 0.25s ease;

    &::-ms-expand {
      display: none;
    }

    &:hover i {
      margin-top: 3px;
    }
  }
}

.arrow-down-select-icon {
  position: absolute;
  right: 2px;
  top: calc(35% - 12px);
  width: 16px;
  height: 16px;
  display: block;
}
