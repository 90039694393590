@import '../../../../styles/abstracts/variables';

.btn {
  width: 100%;

  &--default {
    background-color: $blue;
    border: none;
    color: $white;
    letter-spacing: 0px;
  }

  &--disabled {
    background-color: $gray-button-shadow;
    border: none;
    color: $black-text;
    letter-spacing: 0px;
  }
}
