@import '../../../../styles/abstracts/variables';

@media only screen and (min-width: 1200px) {
  .container-plate-restriccion-desktop {
    .wrapper {
      .image-background-desktop {
        z-index: 1;
        background-image: url('../../../../images//inspection-desktop/inspection-home-desktop/inspection-home-background-desktop.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 100%;
        width: 100%;
        max-height: 242px;
        height: 242px;
      }

      .card-inspection-home {
        z-index: 100;
        background-color: $white;
        margin-top: -9rem;
        max-width: 80%;
        width: 80%;
        height: 70%;
        max-height: 70%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #e9e9e9;
        margin-bottom: 5rem;
      }

      .title {
        display: initial;
      }

      .title__img {
        max-width: 5%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
      }

      .title__text {
        font-size: 40px;
        color: #1a1446;
      }

      .section__img img {
        float: right;
        margin-right: 15rem;
        margin-top: -10rem;
      }

      .section__text h2 {
        font-size: 30px;
        color: #1a1446;
        max-width: 30%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3rem;
        padding-right: 10rem;
      }

      .section__text p {
        max-width: 34%;
        font-size: 20px;
        color: #373c49;
        padding-right: 15rem;
        text-align: justify;
        direction: rtl;
      }

      .section__img-text {
        font-size: 16px;
        max-width: 100%;
        width: 100%;
        margin-top: 2rem;
      }

      .section__btn {
        width: 40em !important;
      }

      .line-black {
        width: 100%;
      }

      .section__btn-expert {
        max-width: 36%;
        float: right;
      }

      .section__btn-finish {
        max-width: 36%;
      }
    }
  }
}
