@import '../fonts/Roboto.scss';
@import './../abstracts/_mixins.scss';

body {
  font-family: 'Roboto', sans-serif;
}

.bold {
  font-weight: bold;
}

.title {
  @include title();

  &-1 {
    font-size: 32px;
  }

  &-2 {
    font-size: 24px;
  }

  &-3 {
    font-size: 18px;
  }

  &-4 {
    font-size: 14px;
  }
}

.text {
  @include text();

  &-1 {
    font-size: 32px;
  }

  &-2 {
    font-size: 24px;
  }

  &-3 {
    font-size: 18px;
  }

  &-4 {
    font-size: 14px;
  }

  &-5 {
    font-size: 24px;
  }
}
