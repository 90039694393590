@import '../../../../styles/abstracts/variables';

@media only screen and (min-width: 1200px) {
  .inspection-schedule-list-container-desktop {
    max-width: 70%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .title-list-cda {
    font-size: 20px !important;
  }

  .wrapper-info-inspection-show-list {
    max-width: 91% !important;
  }

  .link-info {
    display: none !important;
  }

  .letter-color {
    display: none !important;
  }

  .confirm-cda-workshop {
    max-width: 40% !important;
  }
}
